import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import eye from '../../assets/images/eye-1.svg';
import eyeOff from '../../assets/images/eye-off.svg';

import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-login.webflow.css';
import '../../assets/css/custom.css';
import $ from 'jquery';
import 'jquery-ui';
import {
  defaultSPALogoData,
  handleFormSubmitTrigger,
  handleInvalidFieldFocus,
  handleNotificationCloseClick,
  handlePasswordEyeClick,
  removeSpaceFromText,
  resetLoginForm,
} from '../../helpers';
import { Link } from 'react-router-dom';
import Notifications from '../../components/notifications';
import useNotification from '../../hooks/useNotification';
import {
  api,
  deleteCookieOnAllDomains,
  IBLSpinLoader,
} from '@iblai/ibl-web-react-common';
import { AuthContext } from '../../utils/context';
import FreeTrial from '../../components/FreeTrial/FreeTrial';
import MfeContextUI from '../../components/MfeContextUI/MfeContextUI';
import { useDebounce } from '../../hooks/useDebounce';
import Footer from '../../components/Footer/Footer';
import { getParentDomain } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const LOGO_DATA = defaultSPALogoData;

const Login = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [passwordType, setPasswordType] = useState('password');
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [enforceLogin] = useState(queryParams.get('enforce-login'));
  const [redirectTo] = useState(
    queryParams.get('redirect-to')
      ? queryParams.get('redirect-to')
      : localStorage.getItem('redirectTo')
  );
  const [redirectToken] = useState(queryParams.get('redirect-token'));
  const [redirectPath] = useState(queryParams.get('redirect-path'));
  const authData = useContext(AuthContext);
  const [notifications, notify, onClose] = useNotification();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const selectedTenant = queryParams.get('tenant')
      ? queryParams.get('tenant')
      : '';
    if (process.env.REACT_APP_IBL_DIRECT_SSO_URL) {
      api.ibledxplatform.getCsrfToken(1440, () => {
        window.location.href = process.env.REACT_APP_IBL_DIRECT_SSO_URL;
      });
    } else {
      api.ibledxplatform.getCsrfToken(1440);
      if (authData.axd_token && authData.dm_token && !enforceLogin) {
        if (redirectTo) {
          deleteCookieOnAllDomains('userData', window.location.hostname);
          document.cookie = `userData=${encodeURIComponent(
            localStorage.userData
          )}; domain=${getParentDomain(window.location.hostname)}; path=/`;
          const {
            axd_token,
            axd_token_expires,
            userData,
            tenants,
            dm_token_expires,
            tenant,
            selected_tenant,
            dm_token,
            current_tenant,
          } = { ...localStorage };

          const userObject = {
            axd_token,
            axd_token_expires,
            userData,
            tenants,
            dm_token_expires,
            tenant,
            selected_tenant,
            dm_token,
            current_tenant,
          };

          if (redirectToken) {
            const seperator = redirectPath.includes('?') ? '&' : '?';
            api.ibldmcore.getRedirectUrlFromToken(
              { org: tenant, redirectToken },
              (data) => {
                window.location.href = `${
                  data['url']
                }${redirectPath}${seperator}ibl-data=${encodeURIComponent(
                  JSON.stringify(userObject)
                )}`;
                localStorage.removeItem('redirectToken');
                localStorage.removeItem('redirectPath');
              },
              (error) => {
                console.error(
                  'Error exchanging redirect token for a redirect url ',
                  error,
                  '. Redirect token : ',
                  redirectToken
                );
                window.location.href = '/';
              }
            );
          } else {
            window.location.href = `${redirectTo}/sso-login?data=${encodeURIComponent(
              JSON.stringify(userObject)
            )}`;
          }
        }
      } else {
        fetch(`${process.env.REACT_APP_IBL_LMS_URL}/logout`, {
          method: 'GET',
          credentials: 'include',
        });
        localStorage.clear();
      }
    }
    if (redirectTo) {
      localStorage.setItem('redirectTo', redirectTo);
    }
    localStorage.setItem('selected_tenant', selectedTenant);
    if (redirectToken) {
      localStorage.setItem('redirectToken', redirectToken);
    } else {
      localStorage.removeItem('isExternal');
    }
    if (redirectPath) {
      localStorage.setItem('redirectPath', redirectPath);
    } else {
      localStorage.removeItem('redirectPath');
    }
  }, []);

  useDebounce(
    () => {
      handlePasswordEyeClick({
        setPasswordType,
        isPasswordHidden,
        setIsPasswordHidden,
      });
    },
    300,
    [isPasswordHidden]
  );

  useDebounce(
    () => {
      handleFormSubmitTrigger();
      formSubmit();
      handleInvalidFieldFocus();
      handleNotificationCloseClick();
    },
    300,
    []
  );

  const handleResetForm = () => {
    resetLoginForm();
  };

  const handleLoginSubmit = ({
    username,
    password,
    handleResetForm,
    notify,
    retry = true,
  }) => {
    const formData = new FormData();
    formData.append('email_or_username', username);
    formData.append('password', password);

    api.iblwebauth.login(
      formData,
      (data) => {
        window.location.href = '/login/complete';
      },
      (error) => {
        handleResetForm();
        if (error?.status === 403 && retry) {
          api.ibledxplatform.getCsrfToken(1440, () => {
            handleLoginSubmit({
              username,
              password,
              handleResetForm,
              notify,
              retry: false,
            });
          });
        } else {
          notify('Credentials incorrect', error.responseJSON?.message, 'error');
        }
      }
    );
  };

  const formSubmit = () => {
    $('body form.auth-form .auth-submit-btn').on('click', function (e) {
      e.preventDefault();
      let formIsInvalid = false;
      const formParent = $(this).closest('form');
      const actualFormLevel = parseInt(
        formParent.attr('data-auth-actual-level')
      );
      const usernameEmailField = $('form.auth-form input.username');
      const typedUserNameEmail = usernameEmailField.val();

      const userNameEmailTextError = usernameEmailField
        .closest('.auth-input-group')
        .find('.text-error');
      const passwordField = $('form.auth-form input.password');
      const typedPassword = passwordField.val();
      const passwordTextError = passwordField
        .closest('.auth-input-group')
        .find('.text-error');
      if (actualFormLevel === 1) {
        if (!!!removeSpaceFromText(typedUserNameEmail)) {
          //CHECKING IF EMPTY EMAIL/USERNAME FIELD
          formIsInvalid = true;
          usernameEmailField.addClass('invalid');
          userNameEmailTextError.text('This field is required');
          userNameEmailTextError.removeClass('hidden');
        }
        if (!!formIsInvalid) {
          //DON'T CONTINUE IF FORM INVALID
          return false;
        }
        //HIDE PREVIOUS FORM LEVEL & SHOW NEXT FORM LEVEL LAYOUT
        formParent.find('input').removeClass('invalid');
        formParent.find('.text-error').addClass('hidden');
        $(`form.auth-form .auth-input-group[data-auth-level=1]`).fadeOut(
          '',
          () => {
            $(this).removeClass('visible');
            formParent.attr('data-auth-actual-level', '2');
            $(`form.auth-form .auth-input-group[data-auth-level=2]`).addClass(
              'visible'
            );
          }
        );
      } else {
        if (!!!removeSpaceFromText(typedPassword)) {
          //CHECKING IF EMPTY PASSWORD FIELD
          formIsInvalid = true;
          passwordField.addClass('invalid');
          passwordTextError.text('This field is required');
          passwordTextError.removeClass('hidden');
        }
        if (!!formIsInvalid) {
          //DON'T CONTINUE IF FORM INVALID
          return false;
        }
        //SUBMISSION START
        formParent.find('input').removeClass('invalid');
        formParent.find('.text-error').addClass('hidden');
        const submitButtonLabelElement = $(this).find('.auth-submit-btn-label');
        submitButtonLabelElement.text('Submitting');
        $(this).addClass('disabled');
        $(this).find('.btn-spinner-block').removeClass('hidden');
        handleLoginSubmit({
          username: String(typedUserNameEmail).trim(),
          password: String(typedPassword).trim(),
          handleResetForm,
          notify,
        });
        return false;
      }
    });
  };
  return (
    <>
      {(!authData.axd_token || enforceLogin) &&
      !process.env.REACT_APP_IBL_DIRECT_SSO_URL ? (
        <div className="w-layout-vflex auth-page-container">
          <FreeTrial />
          <div className="w-layout-vflex auth-page-block">
            <img
              src={LOGO_DATA.logo}
              loading="lazy"
              alt=""
              className="auth-logo-img"
            />
            <div className="w-layout-vflex auth-page-main-content">
              <h2 className="auth-page-title">{t('Welcome Back')}</h2>
              <div className="auth-form-block w-form">
                <form
                  method="get"
                  data-auth-actual-level="1"
                  name="email-form"
                  data-name="Email Form"
                  data-auth-level-number="2"
                  id="email-form"
                  className="auth-form"
                  data-wf-page-id="659d7f74d3cc18f619efec06"
                  data-wf-element-id="bcda3841-4ea3-bb0c-59a6-36594047bbde"
                >
                  <div
                    data-auth-level="1"
                    className="w-layout-vflex auth-input-group visible"
                  >
                    <input
                      className="auth-input username w-input"
                      autoFocus={true}
                      maxLength="256"
                      name="name"
                      data-name="Name"
                      placeholder={t('Email or Username')}
                      type="text"
                      id="username"
                      required=""
                    />
                    <div
                      className="text-error hidden"
                      role="alert"
                      aria-label="Required Field"
                    >
                      {t('This field is required')}
                    </div>
                  </div>
                  <div
                    data-auth-level="2"
                    className="w-layout-vflex auth-input-group"
                  >
                    <input
                      className="auth-input password-field password w-input"
                      maxLength="256"
                      name="name-2"
                      data-name="Name 2"
                      placeholder={t('Password')}
                      type={passwordType}
                      id="password"
                    />
                    <div
                      className="text-error hidden"
                      role="alert"
                      aria-label="Minimum 8 characters"
                    >
                      {t('Minimum 8 characters')}
                    </div>
                    <Link
                      to={`/forgot-password?redirect-to=${redirectTo}`}
                      className="auth-link-redirect mt-small"
                    >
                      {t('Forgot password?')}
                    </Link>
                    <div className="w-layout-hflex password-eye-block">
                      <img
                        src={!isPasswordHidden ? eyeOff : eye}
                        loading="lazy"
                        alt=""
                        className="password-eye-icon"
                      />
                    </div>
                  </div>
                  <div
                    className="w-layout-hflex auth-submit-btn"
                    role="button"
                    aria-label="Continue"
                  >
                    <div className="auth-submit-btn-label">{t('Continue')}</div>
                    <div className="btn-spinner-block hidden w-embed">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="submit"
                    data-wait="Please wait..."
                    className="submit-button-2 w-button"
                    value="Submit"
                  />
                </form>
                <div className="w-form-done">
                  <div>
                    {t('Thank you! Your submission has been received!')}
                  </div>
                </div>
                <div className="w-form-fail">
                  <div>
                    {t('Oops! Something went wrong while submitting the form.')}
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex auth-redirect-block">
                <div className="text-block-29">
                  {t("Don't have an account")}?
                </div>
                <Link
                  to={`/signup?redirect-to=${redirectTo}`}
                  className="auth-link-redirect"
                >
                  {t('Sign Up')}
                </Link>
                {/*<a href="#" className="auth-link-redirect">Sign Up</a>*/}
              </div>
              <MfeContextUI />
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {process.env.REACT_APP_IBL_DIRECT_SSO_URL && <IBLSpinLoader />}
        </div>
      )}
      {Object.entries(notifications).map(([key, notificationObj]) => (
        <Notifications
          onClose={() => onClose(key)}
          key={key}
          displayMsg={notificationObj.displayMsg}
          headerMsg={notificationObj.headerMsg}
          type={notificationObj.type}
        />
      ))}
    </>
  );
};

export default Login;
